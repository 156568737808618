//TYPOGRAPHY

a{
  color: inherit;
  font-family: inherit;

  &:hover{
    color: inherit;
    text-decoration: none;
  }
}

//SECTION TITLE

.section-title {
  color: #000;
  h1 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 4px;
    padding-left: 18px;
    border-left: 4px solid #C90C0F;
    margin-bottom: 0;
  }
  p {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 0 !important;
  }
}

.gradient {
  display: inline-block;
  color: #C5724E;
  @supports not (-ms-high-contrast: none) {
    background: linear-gradient(90deg, #C5724E, #E0B299);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}