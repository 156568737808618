//FOOTER

#footer {
  background: url("../images/footer-bg.png");
  background-color: #FCFCFC;
  background-size: cover;
  height: 331px;
  background-repeat: no-repeat;
  @media (max-width: $tablet) {
    background-position: -110px 25px;
    background-size: 130% auto;
    background-repeat: no-repeat;
  }
  @media (max-width: $verticalTablet) {
    background: url("../images/footer-mobile-bg.jpg");
    background-size: 100% auto;
    background-position: center -345px;
    background-repeat: no-repeat;
  }
  @media (max-width: $mobile) {
    background: url("../images/footer-mobile-bg.jpg");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media (max-width: $verticalTablet) {
      align-items: flex-start;
    }
  }
}

#footer-logo {
  width: 257px;
  margin-top: 40px;
  margin-bottom: 40px;
  img {
    max-width: 100%;
  }
}

.social-icons {
  display: flex;
  justify-content: space-between;
  a {
    transition: all 0.15s ease-in-out !important;
    &:hover {
      opacity: 0.5;
    }
  }
}