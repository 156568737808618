#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 73px;
  background-color: #000;
  padding: 0 35px;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 90;
  cursor: pointer;
  @media (max-width: $verticalTablet) {
    padding: 0 20px;
    height: 103px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70px;
    height: 30px;
    background-image: linear-gradient(90deg, rgba(255,0,0,0), rgba(0,0,0,1));
    pointer-events: none;
  }
}

#logo {
  display: block;
  width: 115px;
  flex-shrink: 0;
  @media (max-width: $verticalTablet) {
    width: 90px;
  }
  img {
    display: block;
    max-width: 100%;
    transition: all 0.15s ease-in-out;
    &:hover {
      opacity: 0.6;
      filter: alpha(opacity=50);
    }
    @media (max-width: $verticalTablet) {
      margin-bottom: 22px;
    }
  }
}

#navigation {
  display: flex;
  a {
    display: block;
    margin-left: 45px;
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    &.active{
      border-bottom: 2px solid #fff;
    }
    &:hover{
      @media (min-width: 1200px) {
        border-bottom: 2px solid #fff;
      }
    }
    @media (max-width: $verticalTablet) {
      flex-shrink: 0;
    }
  }
}

#nav-bar {
  @media (max-width: $verticalTablet) {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    padding-right: 60px;
    margin-bottom: 5px;
    font-size: 12px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  a {
    @media (max-width: $verticalTablet) {
      margin-left: 0;
      margin-right: 30px;
    }
  }
}
#menu{
  display: flex;
  @media (max-width: $verticalTablet) {
    width: 170%;
  }
}

#language {
  display: flex;
  a {
    &:last-child {
      margin-left: 20px;
    }
    &.active-language {
      border-bottom: 2px solid #fff;
    }
  }
  @media (max-width: $verticalTablet) {
    margin-bottom: 13px;
  }
}
