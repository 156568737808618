//CARDS

.benefits-card {
  text-align: center;
  border: 0;
  border-radius: 0;
  margin-top: 75px;
  display: block;
  @media (max-width: $verticalTablet) {
    margin-top: 50px;
  }
  .benefits-card-img {
    border: 1px solid #C5724E;
    border-radius: 60px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    .card-img-top {
      width: 53px;
    }
  }
  .card-body {
    padding-top: 30px;
    .card-title {
      font-size: 22px;
      color: #C5724E;
      @supports not (-ms-high-contrast: none) {
        background: linear-gradient(90deg, #C5724E, #E0B299);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      min-height: 55px;
      margin-bottom: 25px;
      @media (max-width: $verticalTablet) {
        min-height: auto;
      }
    }
    .card-text {
      font-size: 14px;
      color: #000;
      line-height: 26px;
    }
  }
}