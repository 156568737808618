.btn {
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 2px;
  padding: 13px 20px;
  transition: none !important;
  &:active {

  }
}
.btn-primary{
  background-image: linear-gradient(90deg, #C5724E 1%, #E0B299 100%);
  &:hover {
    background-image: linear-gradient(90deg, #C5724E 1%, #E0B299 100%) !important;
  }
}
.btn-outline-primary{
  border-color: #C5724E;
  color: #C5724E;
  transition: none;
  &:hover{
    background-image: linear-gradient(90deg, #C5724E 1%, #E0B299 100%);
    border: 1px solid #C5724E;
  }
}

.distance-table {
  .btn-primary {
    color: #000;
    &:hover {
      background: linear-gradient(90deg, #C5724E 1%, #E0B299 100%);
    }
  }
}

.btn-link {
  &:hover {
    text-decoration: none;
  }
}

button:focus {
  outline: 0;
}

#scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-image: linear-gradient(90deg, #C5724E 1%, #E0B299 100%);
  padding: 17px;
  opacity: 0;
  transition: opacity 250ms 0s ease;
  &.show{
    opacity: 1;
  }
}