//SECTION

.section {
  padding: 95px 0 90px 0;
  @media (max-width: $verticalTablet) {
    padding: 50px 0;
  }
  &.section-gray-bg {
    background-color: #FCFCFC;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
  }
}

.cities-content {
  h1 {
    color: #A7A8AA;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
  p {
    letter-spacing: 0.8px;
    margin-bottom: 5px !important;
  }
}

.service-points {
  margin-bottom: 30px;
  @media (max-width: $verticalTablet) {
    display: flex;
  }
}

.usa-points {
  @media (max-width: $verticalTablet) {
    margin-right: 100px;
  }
}

.canada-points {
  margin-top: 40px;
  @media (max-width: $verticalTablet) {
    margin-top: 0;
  }
}

.distance {
  @media (max-width: $tablet) {
    margin-right: -30px;
  }
  @media (max-width: $verticalTablet) {
    margin-right: 0;
  }
}

.distance-table {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  @media (max-width: $verticalTablet) {
    margin-top: 0;
  }
  .btn-group {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    .btn{
      display: block;
      padding: 2px 7px 1px 7px;
      font-size: 14px;
      &:hover {
        color: #000;
      }
      &:focus {
        box-shadow: none !important;
      }
      &:active {
        color: #000 !important;
        border-color: #C5724E;
        background-color: transparent;
      }
    }
  }
  .table {
    font-size: 13px;
    tr {
      border: 1px solid #75787B;
    }
    th {
      color: #E4AB86;
      font-weight: 600;
      border: 0;
      padding: 20px 80px 17px 15px;
    }
    td {
      border: 0;
      padding: 20px 0 17px 15px;
    }
  }
}

#cities-distances {
  background-color: #000;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 70px;
    background: url("../images/cities-distances-border.jpg") no-repeat center / cover;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    @media (max-width: 1365px) {
      width: 40px;
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }
  .container {
    position: relative;
    z-index: 11;
  }
  .section-title {
    margin-bottom: 80px;
    @media (max-width: $verticalTablet) {
      margin-bottom: 30px;
    }
  }
  h1 {
    border-color: #C5724E;
  }
  p {
    color: #A7A8AA;
  }
}

#cities-distances-road-wrapper {
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: url("../images/road-bg.png") no-repeat;
  background-size: auto 765px;
  background-position: center right;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: $tablet) {
    background-size: auto 500px;
  }
  @media (max-width: $verticalTablet) {
    background-size: auto 400px;
    position: static;
    height: 400px;
    margin-bottom: -40px;
  }
  img{
    width: 346px;
    @media (max-width: $tablet) {
      width: 265px;
    }
    @media (max-width: $verticalTablet) {
      width: 345px;
    }
  }
}

#cities-distances-road {
  position: relative;
  margin-right: 200px;
  @media (max-width: $tablet) {
    margin-right: 100px;
  }
  @media (max-width: $verticalTablet) {
    margin: auto;
  }
}

#cities-distances-mile {
  font-size: 60px;
  @extend .gradient;
  font-weight: 600;
  position: absolute;
  top: -20px;
  right: -100px;
  height: 60px;
  line-height: 60px;
  width: 100px;
  @media (max-width: $tablet) {
    font-size: 45px;
    top: -35px;
    right: -83px;
  }
  @media (max-width: $verticalTablet) {
    font-size: 60px;
    top: -50px;
    right: -5px;
  }
  small {
    font-size: 16px;
    font-weight: 500;
  }
}

.sub-info {
  font-size: 14px;
  font-style: italic;
  line-height: 25px;
  a {
    color: #fff;
    font-weight: 600;
    &:hover {
      padding-bottom: 4px;
      border-bottom: 1px solid #fff;
    }
  }
}

.section-accordion {
  margin-bottom: 10px;

}
.accordion {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  transition: 0s;
  border: 1px solid #E0E0E0;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: #000;
  }
  &.accordion-active {
    border-bottom: none;
    img {
      transform: rotate(180deg);
    }
  }
}

.panel {
  padding: 0 50px 10px 40px;
  display: none;
  overflow: hidden;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.9px;
  border: 1px solid #E0E0E0;
  border-top: 0;
  @media (max-width: $verticalTablet) {
    padding: 0 20px 0 20px;
  }
  li {
    padding-left: 5px;
    margin: 0 50px 0 20px;
    @media (max-width: $verticalTablet) {
      margin: 0 0 0 15px;
    }
  }
}