#hero {
  height: 695px;
  background-image: url(../images/hero-background.png);
  background-size: auto 100%;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 140px;
  @media (max-width: $tablet) {
    background-size: auto 75%;
  }
  @media (max-width: $verticalTablet) {
    height: 845px;
    padding-bottom: 15px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url(../images/hero-car.png);
    background-color: #000000;
    background-position: 0 20px;
    padding-top: 370px;
  }
  @media (max-width: $mobile) {
    height: 815px;
    padding-top: 270px;
    background-position: 0 70px;
  }
}

#hero-content {
  color: #fff;
  h1 {
    font-size: 46px;
    line-height: 50px;
    @media (max-width: $tablet) {
      font-size: 37px;
  }
    @media (max-width: $verticalTablet) {
      font-size: 27px;
      line-height: 34px;
    }
}
  .text-warning {
    color: #E4AB86 !important;
  }
  p {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 0 !important;
    @media (max-width: $tablet) {
      font-size: 16px;
      margin-top: 15px;
    }
    @media (max-width: $verticalTablet) {
      margin-top: 5px;
      br {
        display: none;
      }
    }
  }
}

#hero-form {
  margin-left: 40px;
  background-color: #fff;
  padding: 18px 25px;
  @media (max-width: $tablet) {
    margin-left: -20px;
  }
  @media (max-width: $verticalTablet) {
    margin-top: 20px;
    margin-left: 0;
  }
  button {
    margin: 30px 0 22px 0;
    letter-spacing: 2.3px;
  }
  small {
    display: block;
    text-align: center;
    color: #4A4A4A;
    font-size: 14px;
    letter-spacing: 1px;
    a {
      font-weight: 500;
      color: #000;
      padding-bottom: 3px;
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid #000;
      }
    }
  }
}

label {
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
  letter-spacing: 1.7px;
}

.form-control {
  height: 50px;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  &:focus {
    border-color: #C5724E;
    box-shadow: 0 0 0 0.2rem rgba(197,114,78,0.35);
  }
}

.reservation-btn span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.reservation-btn span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.reservation-btn:hover span {
  padding-right: 25px;
}

.reservation-btn:hover span:after {
  opacity: 1;
  right: 0;
}