@font-face {
  font-family: 'GothamNarrow';
  src: url('../fonts/GothamNarrow-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'GothamNarrow';
  src: url('../fonts/GothamNarrow-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GothamNarrow';
  src: url('../fonts/GothamNarrow-BookItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'GothamNarrow';
  src: url('../fonts/GothamNarrow-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GothamNarrow';
  src: url('../fonts/GothamNarrow-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}