//PROCESS

.process {
  margin-top: 60px;
}

.process-list{
  @media (max-width: $verticalTablet) {
    display: flex;
    justify-content: space-between;
  }
}

.process-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 10px;
  min-height: 115px;
  @media (max-width: $verticalTablet) {
    flex: 1;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 0;
    min-height: 0;
    display: flex;
    justify-content: space-between;
  }
  &:last-child {
    flex: none;
    &:after {
      display: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &:after {
    content: "";
    width: 1px;
    background-color: #E0E0E0;
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 30px;
    @media (max-width: $verticalTablet) {
      height: 1px;
      width: 100%;
      position: static;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.process-number {
  font-size: 20px;
  font-weight: 600;
  color: #C5724E;
  .active-process & {
    background-image: linear-gradient(90deg, #C5724E 1%, #E0B299 100%);
    border-radius: 60px;
    color: #fff;
  }
  .process-btn {
    width: 60px;
    height: 60px;
    border: 1px solid #C5724E;
    border-radius: 60px;
    box-shadow: 0 4px 9px 0 rgba(228,171,134,0.46);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C5724E;
    font-weight: 500;
    transition: 0s;
    .active-process & {
      border: 0;
      box-shadow: none;
      color: #fff;
    }
    &:hover {
      background: linear-gradient(90deg, #C5724E 1%, #E0B299 100%);
      color: #fff;
      border: 0;
    }
  }
}

.process-content {
  padding-left: 15px;
  color: #C8C9C7;
  cursor: pointer;
  @media (max-width: $verticalTablet) {
    display: none;
  }
  .active-process & {
    color: #000;
  }
  h1 {
    font-size: 22px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0 !important;
  }
}

#process-slider{
  padding-left: 30px;
  @media (max-width: $verticalTablet) {
    padding-left: 0;
  }
  .process-content{
    display: none;
    @media (max-width: $verticalTablet) {
      display: block;
      text-align: center;
      padding-left: 0;
      padding-bottom: 45px;
      min-height: 130px;
      color: #000;

    }
  }
  .swiper-container{
    height: 450px;
    @media (max-width: $verticalTablet){
      height: auto;
    }
  }
  .swiper-slide{
    img{
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}